import { io } from "socket.io-client";
import HOST_URL from "../../../config.js"
export default {
    addNewTopic(state, newTopic) {
        state['liveSockets'].push(newTopic);
    },
    addfields(state, dtls) {
        let sockets = state.liveSockets;
        for (let i = 0; i < sockets.length; i++) {
            if (dtls.topic === sockets[i][dtls.topic]) {
                state.liveSockets[sockets[i][dtls.topic][fields] = dtls.fields];
                break;
            }
        }
    },
    setSubscriptionFlag(state, dtls) {
        if (state['isSubscribed'][dtls.topic]) {
            state['isSubscribed'][dtls.topic] = dtls.flag;
        } else {
            state['isSubscribed'][dtls.topic] = true;
        }
    },
    socketConnection(state) {

        state.socket = io(HOST_URL.SERVER_URL, { transports: ["websocket", "xhr-polling", "htmlfile", "jsonp-polling"] });

        state.socket.on("error", (error) => {

        })
        state.socket.on("connect", () => {

        })
        state.socket.on("disconnect", () => {

        })
    }
}