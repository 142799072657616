export default {
    GETTING_ALERTS: () => "miscellaneous/type/values",
    MISCELLANEOUS_LIST: () => "miscellaneous/list",
    GETTING_MBL_NUMBERS: () => "smscommunicationconfiguration/search",


    COMMUNICATION_CONFIG_SEARCH: () => "communicationconfiguration/search",
    COMMUNICATION_CONFIG_SAVE_UPDATE: () => "communicationconfiguration/saveorupdate",
    COMMUNICATION_CONFIG_LIST: () => "communicationconfiguration/list",

    //ALERT ANNOTATIONS
    ANNOTATIONS_LIST: () => "alertannotations/list",
    ANNOTATIONS: () => "public/annotations",
    ALERTS_LIST: () => "public/alerts_list",
    ALERT_HIST: ()=> "public/timelog/alertsDetails",
    SAVEORGET_ALERT_PREF: ()=>"public/timelog/alertPreferences",
    PREV_ALERTS: ()=>"public/timelog/alertsHistoryDetails"

}
// miscellaneous/type/values