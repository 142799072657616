export default {
    GET_WELLS: () => "public/wells",
    GET_WELL_DETAILS: () => "public/well-details",
    GET_DEPTH_LOG_DETAILS: () => "public/depthlog",
    GET_COUNT_WELLBORE_DEPTH_LOGS: () => "public/depthlog/count",
    GET_COLUMNS_DEPTH_LOG_TABLE: () => "public/depthlog/tags",
    GET_MIN_MAX_INFO: () => "public/depthlog/minmax",
    GET_DATA_FOR: () => "public/depthlog/get-value-at-depth",
    GET_LOG_TYPE: ({ logType = '' }) => `public/directionlog/${logType}`,

    // Cassandra Apis
    GET_DEPTH_TAGS: () => "public/tags/depth",
    GET_LOG_AT_DEPTH: () => "public/depthlog/latest",
    GET_LOG_AT_TIME: () => "public/timelog/latest",
    GET_TIME_TAGS: () => "public/tags/time",
    GET_DEPTH_LOGS: () => "public/depthlog",
    GET_DEPTH_LOGS_SINGLE: () => "public/depthlog-single",
    GET_WELL_DATA: () => "public/well-data",
    GET_MIN_MAX_DEPTH: () => "public/depthlog/minmax",
    GET_MIN_MAX_TIME: () => "public/timelog/minmax",
    GET_TIME_LOGS: () => "public/timelog/nthdata",
    GET_RIG_ACTIVITY: () => "public/timelog/rig-activity",
    GET_DATATABLE_DATA: () => "public/logs/data-table",
    GET_WELL_INFORMATION: () => "public/well-info",
    GET_TRAJECTORY_PLAN_ACTUAL_DATA: () => "public/logs/trejectory-plan-actual",
    GET_TORQUE_AND_DRAG_DATA: () => "public/torque-and-drag",
    GET_TORQUE_AND_DRAG_DATA_TIME: () => "public/torque-and-drag-data",

    // GET_DIRECTIONAL_TRAJECTORY_DATA: () => "public/logs/directional/trajectory",
    GET_DIRECTIONAL_TRAJECTORY_DATA: () => "public/directionlog/trajectory",
    GET_TORQUE_AND_DRAG_WELL_INFO: () => "public/torque-and-drag-wells",
    GET_TORQUE_AND_DRAG_WELLS_INFO: () => "public/t-a-d-wells",
    GET_TORQUE_AND_DRAG_PLANNED_DATA: () => "public/torque-and-drag-planned-data",
    SAVE_BHA: () => "public/save-bha",
    GET_SAVE_BHA: () => "public/list-save-bha",
    GET_WELL_FORMATION: () => "public/well-formations",
    GET_WELL_GEOMETRY: () => "public/well-geometry",
    GET_OPTIMIZATIONS: () => "public/optimizations",
    GET_WELL_SECTIONS: () => "public/well-sections",
    CLEAR_CACHE: () => "public/well-corellation-clearcache",

    //Unit conversion 
    GET_UNIT_CONVERSION_FACTORS_DETAILS:()=>"public/unit_conversion_details",


    //Delete cache from serve
    DELETE_SERVER_CACHE:()=>"public/timelog/clear-lokidb-collection",

    //JAVA - BOOSTER PUMP SERVICES
    BOOSTER_PUMP_SEARCH: ()=> "surfaceequipment/search/by-time-and-depth",
    BOOSTER_PUMP_SAVE: ()=> "surfaceequipment/saveorupdate/boosterpumps"
}