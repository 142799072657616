import types, { CHANGE_ZINDEX } from './mutation-types';

export default {
    setSelectedWellDisplayname({ commit }, payload) {
        commit(types.SET_WELL_NAME_FROM_AUTOCOMPLETE, payload);
    },
    deleteAlertScreen({ commit }, payload) {
        commit("DELETE_ALERT_SCREEN", payload);
    },
    hydraulicCalculator({ commit }, payload) {
        commit("hydraulicCalculator", payload);
    },
    correlationLeftBar({ commit }, { display }) {
        commit("correlationLeftBar", display);
    },
    correlationMap({ commit }, { display , where , widgetId}) {
        commit("correlationMap", {display, where, widgetId});
    },
    correlationMapStreamSync({ commit }, { display, value }) {
        commit("correlationMapStreamSync", { display, value });
    },
    correlationMapSyncScale({ commit }, payload) {
        commit("correlationMapSyncScale", payload);
    },
    lockevent({ commit }, payload) {
        commit("lockevent", payload);
    },
    daysvsdepth({ commit }, payload) {

        commit("daysvsdepth", payload);
    },
    daysVsDepthToggle({ commit }, payload) {
        commit("daysVsDepthToggle", payload);
    },

    clearSelectedDisplay({ commit }, { savedDbId }) {
        commit('CLEAR_DISPLAYS', { savedDbId });
    },
    async fullScreen({ commit }, { displayId }) {
        commit('FULLSCREEN', { displayId });
    },
    setDisplay({ commit }, details) {
        commit('setDisplay', details);
    },
    setContextId({ commit }, details) {
        commit('SETCONTEXTID', details);
    },
    setSelectedDisplay({ commit }, selected) {
        commit('setSelectedDisplay', selected);
    },
    async setTags({ commit }, details) {
        commit('setTags', { details });
    },
    async setTrejectoryTags({ commit, state }, details) {
        commit('setTrejectoryTags', { details });
    },
    toggleDragHandler({ commit }, { displayId }) {
        commit(types.DRAGGABLE_ALL, { displayId })
    },
    addNewDisplay({ commit, state }, xy) {
        let rectProps =
        {
            'width': xy.elProps.width,
            'height': xy.elProps.height,
            'top': xy.y,
            'left': xy.x,
            'draggable': true,
            'resizable': true,
            'minw': 10,
            'minh': 10,
            'axis': 'both',
            'parentLim': true,
            'snapToGrid': false,
            'aspectRatio': false,
            'zIndex': 3,
            // 'color': '#E6C27A',
            'color': xy.elProps.color,
            'active': false,
            'class': 'box-shaddow',
            'name': xy.elProps.name,
            isWidget: true,
            isCorrelationMap: true,
            
            ...xy.elProps,
        }
        // 
        if (xy.elProps.displayId != null) {
            rectProps.displayId = xy.elProps.displayId;
        }
        if (xy.elProps.savedDbId != null) {
            rectProps.savedDbId = xy.elProps.savedDbId;
        }
        if (xy.elProps.savedId != null) {
            rectProps.savedId = xy.elProps.savedId;
        }
        if (xy.elProps.savedBgId != null) {
            rectProps.savedBgId = xy.elProps.savedBgId;
        }
        if (xy.elProps.savedName != null) {
            rectProps.savedName = xy.elProps.savedName;
        }
        if (xy.elProps.savedDbName != null) {
            rectProps.savedDbName = xy.elProps.savedDbName;
        }
        if (xy.elProps.savedBgName != null) {
            rectProps.savedBgName = xy.elProps.savedBgName;
        }
        if (xy.elProps.widgetId != null) {
            rectProps.widgetId = xy.elProps.widgetId;
        }
        rectProps.isTaD = xy.elProps.isTaD;
        rectProps.isHydraulics = xy.elProps.isHydraulics;
        commit(types.ADD_NEW_RECT, rectProps);
    },

    setActive({ commit, state }, { id }) {
        for (let i = 0, l = state.rects.length; i < l; i++) {
            if (i === id) {
                commit(types.ENABLE_ACTIVE, i);
                continue;
            }

            commit(types.DISABLE_ACTIVE, i);
        }
    },

    changeDisplayName({ commit, state }, { savedId, displayId, savedName }) {
        commit('CHANGE_SAVED_NAME', { savedId, displayId, savedName });
    },

    dashboardClicked({ commit, state }, { savedDbId }) {
        commit('DASHBOARD_CLICKED', { savedDbId });
    },
    screenClicked({ commit, state }, { savedId }) {
        commit('SCREEN_CLICKED', { savedId });
    },
    updateCall({ commit, state }, { }) {
        commit('UPDATE_CALL', {});
    },
    unsetActive({ commit }, { id }) {
        // 
        commit(types.DISABLE_ACTIVE, id);
    },
    setWidth({ commit }, { id, width }) {
        commit(types.CHANGE_WIDTH, { id, width });
    },
    setHeight({ commit }, { id, height }) {
        commit(types.CHANGE_HEIGHT, { id, height });
    },
    setTop({ commit }, { id, top }) {
        commit(types.CHANGE_TOP, { id, top });
    },
    setLeft({ commit }, { id, left }) {
        commit(types.CHANGE_LEFT, { id, left });
    },
    changeZToBottom({ commit, state }, { displayId }) {
        // 
        let zIndex = 0;
        let index = -1;
        for (let i = 0; i < state.rects.length; i++) {
            if (state.rects[i].displayId == displayId) {
                index = i
            }
            if (zIndex > state.rects[i].zIndex) {
                zIndex = state.rects[i].zIndex;
            }
        }
        commit(types.CHANGE_ZINDEX, { index, zIndex: zIndex });
    },
    changeZToTop({ commit, state }, { displayId }) {
        let zIndex = 0;
        let index = -1;
        for (let i = 0; i < state.rects.length; i++) {
            if (state.rects[i].displayId == displayId) {
                index = i
            }
            if (zIndex < state.rects[i].zIndex) {
                zIndex = state.rects[i].zIndex;
            }
        }
        commit(types.CHANGE_ZINDEX, { index, zIndex: ++zIndex });
    },
    toggleDraggable({ commit, state }, { displayId }) {
        let index = -1
        for (let i = 0; i < state.rects.length; i++) {
            if (state.rects[i].displayId == displayId) {
                index = i
            }

        }

        commit(types.TOGGLE_DRAGGABLE, { index });
    },
    toggleResizable({ commit, state }, { displayId }) {
        let index = -1
        for (let i = 0; i < state.rects.length; i++) {
            if (state.rects[i].displayId == displayId) {
                index = i
            }
        }
        if (state.rects[index] && !state.rects[index].resizable) {
            commit(types.ENABLE_RESIZABLE, index);
        } else {
            commit(types.DISABLE_RESIZABLE, index);
        }
    },


    toggleLeftSideBar({ commit }) {
        commit(types.LEFT_SIDE_BAR)
    },
    toggleLeftSideDbBar({ commit }) {
        commit(types.LEFT_SIDE_DB_BAR)
    },
    togglrRightSideBarWidth({ commit }) {
        commit(types.RIGT_SIDE_BAR_WIDTH)
    },



    minimizeRect({ commit }, { displayId }) {
        commit(types.MINIMIZE_RECT, { displayId })
    },
    maximizeRect({ commit }, { displayId }) {
        commit(types.MAXIMIZE_RECT, { displayId })
    },
    minimizeDisplay({ commit }, { displayId }) {
        commit(types.MINIMIZE_DISP, { displayId })
    },
    maximizeDisplay({ commit }, { displayId, minimizedIndex }) {
        commit(types.MAXIMIZE_DISP, { displayId, minimizedIndex })
    },
    deleteDisplay({ commit }, { displayId, minimizedIndex }) {
        commit(types.DELETE_DISPLAY, { displayId, minimizedIndex })
    },
};
