<template>
    <div class="container ml-auto mr-auto w-3/5 text-white text-2xl h-full overflow auto">

      <div @click="()=> $router.push('/auth/login')" class="absolute __back_icon bg-emerald-600" style="color: var(--textColor); top: 6%; left: 5%; font-size: 160%;"><i class="fa fa-arrow-left" aria-hidden="true"></i></div>
      
      <h2 class="__privacy_heading">Privacy Policies</h2>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p class="text-center">Content will update soon</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>This policy is effective as of 04 May 2023.</p>
      <p>
        Privacy Policy created for Apollovue by
        <a
          href="https://www.apollodart.com/"
          title="Privacy Policy created for ODOAN"
          >Apollodart.</a
        >
      </p>
      <!-- </div> -->
    </div>
  </template>
  <script>
  import Loader from "../layouts/Loader.vue";
  import axios from "axios";
  export default {
    data() {
      return {
        tomail_disabled: true,
        toMail: "support@apollodart.com",
        from: "",
        contact: null,
        subject: "Issue-",
        body: "",
        // attachment: null,
        mail_Sending: false,
        loader: false,
        // capturedImage: null,
        // fileType: null,
        // showAttachmentInfo: false,
        // login_user_as_cc: '',
      };
    },
    components: {
      Loader,
    },
    methods: {

    },
  };
  </script>
  <style scoped>
  .__privacy_heading {
      font-size: 76px;
      line-height: normal;
  }
  .__back_icon{
    border-radius: 50%;
    padding: 1%;
    cursor: pointer;
  }
  </style>
  