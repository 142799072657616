const HOST_URL = {
  //  STAGING IPS
  SERVER_URL: "https://demo.apollodart.com/",
  SOCKET_URL: "wss://demo-stream.apollodart.com/",
  ADMINISTRATOR_URL: "https://demo-usrm.apollodart.com/",
  DATASERVICES_URL: "https://demo-dataservices.apollodart.com/apollosolutions/",
  APOLLO_UTIL_URL: "https://demo-dataservices.apollodart.com/apolloutils/",
  TND_SERVICE: "https://demo-killsheets.apollodart.com/",
  LWD_UPLOAD: "https://demo-lwd.apollodart.com/",
  SWEET_SPOT: "https://demo-sst.apollodart.com/",
  CH_BASE:"https://demo.apollodart.com/",
  // Zulip chat realated config
  ZULIP_REALM: "https://zulip.apollodart.com",
   AWS_S3_URL: "https://apollodart-logos.s3.amazonaws.com/"
};
export default {
  HOST_URL,
};
