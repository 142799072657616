export default {
    isTopicExists({ state }, topic) {
        let topicExists = false;
        let sockets = state['liveSockets'];
        if (sockets.length > 0) {
            for (let i = 0; i < sockets.length; i++) {
                let topics = sockets[i].topics;
                if (topics && topics.length > 0) {
                    for (let i = 0; i < topics.length; i++) {
                        
                        if (topics[i][topic]) {
                            topicExists = true
                            break;

                        }
                    }
                }
                if (topicExists) {
                    return true;
                } else {
                    false;
                }
            }

        } else {
            return false;
        }
    },
    setSubscriptionFlag({ commit, state }, { topic, flag }) {
        commit("setSubscriptionFlag", { topic, flag });
    },
    addSocketDetails({ commit, state }, dtls) {
        
        let id = dtls.socketId;
        let displayId = dtls.display;
        let sockets = state['liveSockets'];
        let exists = false;
        let displays = [];
        let dtlsFields = dtls.fields;
        let fields;
        let topic = dtls.topic;
        let topicExists = false;
        let fieldsTobeAdded = false;
        let newTopc;
        if (sockets.length > 0) {
            for (let i = 0; i < sockets.length; i++) {
                let topics = sockets[i].topics;
                
                if (topics && topics.length > 0) {
                    for (let i = 0; i < topics.length; i++) {
                        
                        if (topics[i][topic]) {
                            topicExists = true;
                            let fields = topics[i][topic]['fields'];
                            
                            

                            let displays = topics[i][topic]['displays'];
                            if (dtlsFields.length > 0) {


                                for (let l = 0; l < dtlsFields.length; l++) {


                                    if (fields.indexOf(dtlsFields[l]) === -1) {
                                        fieldsTobeAdded = true;
                                        fields.push(dtlsFields[l]);
                                    }
                                }
                                if (fieldsTobeAdded) {
                                    commit("addfields", { fields, topic });
                                    break;
                                }

                            }

                        }
                    }
                    if (!topicExists) {
                        let newTopic = {
                            id: id,
                            topics: [
                                {
                                    [topic]: { "fields": dtlsFields, "displays": [displayId] },
                                }
                            ]
                        }
                        commit("addNewTopic", newTopic);
                        break;
                    }

                } else {
                    let newTopic = {
                        id: id,
                        topics: [
                            {
                                [topic]: { "fields": dtlsFields, "displays": [displayId] },
                            }

                        ]
                    }
                    commit("addNewTopic", newTopic);
                    break;
                }

            }

        } else {
            let newTopic = {
                id: id,
                topics: [
                    {
                        [topic]: { "fields": dtlsFields, "displays": [displayId] },
                    }

                ]
            }
            
            commit("addNewTopic", newTopic);

        }
        
    },
    initSocketConnection({ commit, state }) {
        commit("socketConnection")
    }
}



