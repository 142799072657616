<template>
  <div>
    <Loader v-if="$store.state.data.isLoading"> </Loader>
    <AdminLayout></AdminLayout>
    <!-- <p class="bg-yellow-800"> {{ viewportHeight }},{{ viewportWidth }}</p> -->
    <div class="flex flex-row mx-auto w-screen bottom-0 bg-yellow-800" style="height: 100%; z-index: 1">
      <div :class="'container_'" :id="'container_'">
        <div :class="'menu'" :id="'menu'">
          <label class="item check-label" @click="toggleDraggable()" for="dragPosition">
            Drag
            <input class="checkbox" type="checkbox" id="dragPosition" :checked="$store.state.rect.rects.every(
      (element) => element.draggable == true
    )
      " />
          </label>

          <label class="item check-label" for="resizePosition" @click="toggleResizable()">
            Resize
            <input class="checkbox" type="checkbox" id="resizePosition" :checked="$store.state.rect.rects.every(
      (element) => element.resizable == true
    )
      " />
          </label>
          <div class="item delete bg-red-300 hover:bg-red-500" @click="deleteWidget()">
            Delete
          </div>
        </div>
      </div>

      <div class="list w-auto" v-bind:id="dashId">
        <div @drop="onDrop($event, 1)" @dragenter.prevent @dragover.prevent class="h-full bottom-0 w-screen central_bg">
          <VueDragResize v-for="(rect, index) in rects" :snapToGrid="false" :gridX="20" :id="rect.displayId"
            :key="rect.displayId" :w="rect.width" :h="rect.height" :x="rect.left" :y="rect.top" :parentW="listWidth"
            :parentH="listHeight" :axis="rect.axis" :isActive="rect.active" :minw="rect.minw" :minh="rect.minh"
            :isDraggable="rect.draggable" :isResizable="rect.resizable" :parentLimitation="rect.parentLim"
            :aspectRatio="rect.aspectRatio" :z="rect.zIndex" :contentClass="rect.class"
            v-on:activated="activateEv(index, rect.displayId)" v-on:deactivated="deactivateEv(index)"
            v-on:dragging="changePosition($event, index)" v-on:resizing="changeSize($event, index)"
            :isMinimize="rect.isMinimize" :class="rect.isMinimize ? 'displayNone' : ''">
            <div :class="'filler'" :id="rect.displayId">
              <Display :openPropertyFn="() => openProperty(index, rect)" :width="rect.width" :height="rect.height"
                :displayId="rect.displayId" :isMinimize="rect.isMinimize" :id="rect.displayId" :onMinMax="windowToggle"
                aria-label="Minimize" :class="rect.isMinimize ? classes : ''" :isTaD="rect.isTaD"
                :isCorrelation="rect.isCorrelation" :isLwd="rect.isLwd" :isAlertScreen="isAlertScreen"
                :displayIndex="index" :isReplay="rect.isReplay" :isCorrelationMap="rect.isCorrelationMap">
              </Display>
            </div>
          </VueDragResize>
          <transition name="slide">
            <div class="flex flex-col w-64 shadow-xl sm:rounded-lg" v-if="isPropertyFile">
              <div class="absolute right-0 right_prop_width h-100">
                <div class="list h-full right_list_prop" id="list">
                  <component :is="
                      selectedWidgetProperties.name &&
                      selectedWidgetProperties.name.replace(/\-/g, '') +
                        'Properties'
                    " :widgetId="selectedWidgetProperties.widgetId" :displayId="selectedWidgetProperties.displayId"
                    :id="selectedWidgetProperties.widgetId" :properties="selectedWidgetProperties"
                    :widgetIndex="selectedWidgetProperties.index" :toggle_right_Sidebar="this.toggle_right_Sidebar"
                    :toggle_right_margin="this.toggle_right_margin" :closeNavigation="this.closeRightNavigation">
                  </component>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <BottomMinimizer />
    <ChatBot class="ml-auto" />

    <!-- modal for inactivity starts -->
    <div v-if="inactive"
      class="inactivityPopup overflow-x-hidden overflow-y-auto outline-none focus:outline-none justify-center fixed top-0 left-0 bottom-0 right-0 items-center w-full md:w-10/12 mx-auto flex"
      style="background: var(--inactivePopBg); opacity: 0.9; z-index: 99999">
      <div class="relative w-3/12 my-6 mx-auto max-w-10/12" style="width: auto">
        <div class="border-0 rounded-lg relative flex flex-col w-full outline-none focus:outline-none p-4">
          <!-- content -->
          <div class="py-3 pt-0 mt-2">
            <p class="fontSize text-center" style="color: var(--textColor)">
              1 hour of inactivity detected. Application will be logged out in
              <span class="primary_text">{{ `${min_counter}` }} : {{ `${sec_counter}` }}</span><span> seconds</span>
            </p>
          </div>
          <div class="pt-1 flex justify-center">
            <button
              class="fontSize modalbtns bgClr text-white focus:outline-none hover:text-white my-2 flex rounded-full mr-2 uppercase"
              style="border-color: currentColor; padding: 0.4rem 1.2rem" @click="stay_active">
              Stay Active
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal for inactivity ends -->
  </div>
</template>

<script>
import generalDrillingDefault from "../js/generalDrillingDefault";
import { mapGetters, mapActions } from "vuex";
import { useReplay } from "../composable/useReplay.js";
import configjs from "../config";
import helperServices from "../helper-services.js";

import { ref } from "vue";
import apiService from "../api/services";

var dragX = null;
var dragY = null;
var gx = null;
var gy = null;
var elProps = null;

var elId = null;

let container_ = "";
let menu = "";
var test = function (a, b) {
  gx = a;
  gy = b;
};
import { defineAsyncComponent } from "vue";
export default {
  name: "CentralPanel",
  components: {
    AdminLayout: defineAsyncComponent(() => import("../layouts/Admin.vue")),
    Loader: defineAsyncComponent(() => import("../layouts/Loader.vue")),
    VueDragResize: defineAsyncComponent(() =>
      import("./DragAndDrop/vue-drag-resize.vue")
    ),
    Display: defineAsyncComponent(() =>
      import("./Widgets/Display/Display.vue")
    ),
    numericProperties: defineAsyncComponent(() =>
      import("./Navbars/PropertyFileBar.vue")
    ),
    verticalbarProperties: defineAsyncComponent(() =>
      import("./Navbars/VerticalBarProperty.vue")
    ),
    directionalProperties: defineAsyncComponent(() =>
      import("./Navbars/DirectionalProperties.vue")
    ),
    BackgroundProperties: defineAsyncComponent(() =>
      import("./Navbars/Backgroundproperty.vue")
    ),
    plotlygraphProperties: defineAsyncComponent(() =>
      import("./Navbars/PlotlyGraphProperties.vue")
    ),

    BottomMinimizer: defineAsyncComponent(() =>
      import("./Navbars/BottomMinimizer.vue")
    ),
    labelProperties: defineAsyncComponent(() =>
      import("./Navbars/LabelPropertiesBar.vue")
    ),
    gaugeProperties: defineAsyncComponent(() =>
      import("./Navbars/CircularPropertiesBar.vue")
    ),
    datatableProperties: defineAsyncComponent(() =>
      import("./Navbars/DataTableProperties.vue")
    ),
    planvsactualProperties: defineAsyncComponent(() =>
      import("./Navbars/PlanVsActualProperties.vue")
    ),
    linegraphProperties: defineAsyncComponent(() =>
      import("./Navbars/TvdSectionProperties.vue")
    ),
    linegraphtvdProperties: defineAsyncComponent(() =>
      import("./Navbars/VerticalSectionProperties.vue")
    ),

    AlertscreenProperties: defineAsyncComponent(() =>
      import("./Navbars/AlertscreenProperties.vue")
    ),
    swaborsurgeProperties: defineAsyncComponent(() =>
      import("./Navbars/SwabSurgeProperties.vue")
    ),
    hydrauliccalculatorProperties: defineAsyncComponent(() =>
      import("./Navbars/HydraulicCalcProperties.vue")
    ),
    DirectionalDifficultyProperties: defineAsyncComponent(() =>
      import("./Navbars/DDIProperties.vue")
    ),
    XYAxisProperties: defineAsyncComponent(() =>
      import("./Navbars/XYAxisProperties.vue")
    ),
    MSEProperties: defineAsyncComponent(() =>
      import("./Navbars/MseProperties.vue")
    ),
    DEXPONENTProperties: defineAsyncComponent(() =>
      import("./Navbars/DexponentProperties.vue")
    ),
    ChatBot: defineAsyncComponent(() => import("./ChatBot/ChatBot.vue")),
  },

  data() {
    return {
      viewportWidth: 0,
      viewportHeight: 0,
      isAlertScreen: false,
      listWidth: 0,
      listHeight: 0,
      toggle_right_Sidebar: "0",
      toggle_right_margin: "17",
      show: true,
      propertyIndex: -1,
      selectedWidgetConfig: {},
      isRightSideBar: false,
      activatedIndex: -1,
      navbarOpen: false,
      isOpen: false,
      isRightOpen: false,
      dashId: null,
      classes: "",
      darkMode: false,
      window: {
        width: 0,
        height: 0,
      },
      draggedScreenDet: null,
      mnemonic_groups: {},
      completeData: {},
      isHydraulics: false,
      inactivityTimer: null,
      inactivityThreshold: configjs.HOST_URL?.INACTIVITY_THRESHOLD || 3600000,
      logoutThreshold: configjs.HOST_URL?.LOGOUT_THRESHOLD || 900000,
      inactive: false,
      logout_timer: null,
      min_counter: "",
      sec_counter: 60,
      sec_counter_interval: null,
    };
  },
  setup() {
    const test = ref("Maindash Menu");
    const { setData } = useReplay();
    return {
      setData,
    };
  },
  async beforeMount() {
    let id = "id" + Math.random().toString(16).slice(2);
    this.dashId = id;
    elId = id;

    // Context Menu Popup
    container_ = document.querySelector("#app");
    menu = document.querySelector("#menu");

    if (container_.addEventListener) {
      container_.addEventListener("contextmenu", this.addContextEvent);
    } else {
      document.attachEvent("oncontextmenu", function () {
        // alert("You've tried to open context menu");
        window.event.returnValue = false;
      });
    }
    container_.addEventListener("click", this.hideMenu);
  },
  mounted() {
    

    try {
      this.viewportWidth = window.innerWidth;
      this.viewportHeight = window.innerHeight;
      addEventListener("resize", (event) => {
        this.viewportWidth = window.innerWidth;
        this.viewportHeight = window.innerHeight;
      });

      this.$store.subscribe((mutation, state) => {
        if (mutation.type == "rect/DARK_MODE") {
          // alert("In central panel " + this.$store.state.rect.darkmode)
          if (
            this.$store.state.rect.darkmode ||
            document.querySelector("body").classList.contains("dark-mode")
          ) {
            this.light();
          } else {
            this.dark();
          }
        }
        // if (mutation.type == "disp/setContextId") {
        //   this.addContextEvent({})
        // }
      });
      let listEl = document.getElementById(this.dashId);
      elId = this.dashId;

      this.listWidth = listEl.clientWidth;
      this.listHeight = listEl.clientHeight;

      window.addEventListener("resize", () => {
        this.listWidth = listEl.clientWidth;
        this.listHeight = listEl.clientHeight;
      });
      // CHECKING SCREEN ACTIVITY CODE STARTS
      //   this.resetInactivityTimer()
      // // Reset the timer when a user action is detected (e.g., mousemove, keypress)
      //   document.addEventListener('mousemove', this.resetInactivityTimer);
      //   document.addEventListener('keypress', this.resetInactivityTimer);
      // Add more event listeners for other user actions as needed
      // CHECKING SCREEN ACTIVITY CODE ENDS
    } catch (error) {
      console.error(error);
    }
    // this.showSavedDisplay();
  },
  beforeUnmount() {
    document.removeEventListener("oncontextmenu", this.addContextEvent);
    document.removeEventListener("click", this.hideMenu);
    // document.removeEventListener('mousemove', this.resetInactivityTimer);
    // document.removeEventListener('keypress', this.resetInactivityTimer);
    clearTimeout(this.inactivityTimer);
    clearTimeout(this.logout_timer);
    clearInterval(this.sec_counter_interval);
  },
  computed: {
    // ...mapGetters({
    //   displaysDetails: "disp/getDilplays",
    // }),
    displaysDetails() {
      return this.$store.state.disp.displays;
    },
    rects() {
      let allRects = this.$store.state.disp.rects;
      // let displays = allRects.filter(this.onlyDisplays);
      allRects.forEach((each) => {
        if (each?.savedName == "LWD" || each?.savedName == "SWAB AND SURGE") {
          each.height = window?.innerHeight - 90;
          each.width = window?.innerWidth - 10;
        }
        return each;
      });
      return allRects.filter(this.onlyDisplays);
    },
    isPropertyFile() {
      return this.$store.state.rect.toggle_right_Sidebar;
    },
    selectedWidgetProperties() {
      console.log(
        "Alert--->>>>>>selectedWidgetProperties",
        this.$store.state.rect.selectedWidgetProps
      );
      let selectedWidgetProperties = this.$store.state.rect.selectedWidgetProps;
      selectedWidgetProperties.name &&
        selectedWidgetProperties.name.replace(/\-/g, "") + "Properties";
      console.log(
        "Alert--->>>>>>selectedWidgetProperties",
        selectedWidgetProperties.name &&
        selectedWidgetProperties.name.replace(/\-/g, "") + "Properties"
      );

      return this.$store.state.rect.selectedWidgetProps;
    },
  },
  watch: {
    displaysDetails: {
      deep: true,
      handler: async function (newVal) {
        console.log("centralpannel watch::", newVal);
        if (
          this.draggedScreenDet &&
          this.draggedScreenDet.isReplay &&
          this.draggedScreenDet.savedName != "TORQUE AND DRAG" &&  this.draggedScreenDet.savedName != "DIRECTIONAL" &&  this.draggedScreenDet.savedName != "KILL SHEET" && 
          this.draggedScreenDet.savedName != "SWAB AND SURGE" && this.draggedScreenDet.savedName != "WELL CORRELATION" && this.draggedScreenDet.savedName != "LWD"
        ) {
          const wellDet = newVal[this.draggedScreenDet.displayId];
          if (wellDet && wellDet.wellId) {
            
            let logDetails = await helperServices.getIndexDetails(
              wellDet.wellId
            );

            console.log(" logDetails : ", logDetails)
            let all_mnemonics = logDetails.data.logs[wellDet.logType].mnemonics;
            const source_mnemonice = logDetails.data.logs[wellDet.logType].source;
            const tags = logDetails.data.logs[wellDet.logType].tags;
            await this.mapTypesMnemonicsDetails(
              all_mnemonics,
              source_mnemonice,
              tags
            );
            await this.fetchMnemonicDetailsfromDb(
              newVal[this.draggedScreenDet.displayId]
            );
          }
        }
      },
    },
    // CHECKING SCREEN ACTIVITY CODE STARTS
    inactive(val) {
      if (val) {
        clearTimeout(this.logout_timer);
        clearInterval(this.sec_counter_interval);
        // setting mins
        this.min_counter = this.logoutThreshold / (1000 * 60);
        this.min_counter--;
        // setting seconds
        this.sec_counter = 60;
        this.sec_counter_interval = setInterval(() => {
          this.sec_counter--;
          if (this.sec_counter == 0) {
            // setting mins and seconds
            this.min_counter--;
            this.sec_counter = 60;
          }
          if (this.min_counter < 0) {
            this.min_counter = 0;
            this.sec_counter = 0;
            clearInterval(this.sec_counter_interval);
          }
        }, 1000);
        this.logout_timer = setTimeout(this.logout, this.logoutThreshold);
      } else {
        clearTimeout(this.logout_timer);
        clearInterval(this.sec_counter_interval);
      }
    },
    // CHECKING SCREEN ACTIVITY CODE ENDS
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    ...mapActions({ saveReplayTostore: "data/saveReplayDetailsToStore" }),
    async mapTypesMnemonicsDetails(menomenicsDet, source_mnemonice, tags) {
      
      let all_mnemonics = menomenicsDet;
      console.log("step1", source_mnemonice, tags);
      this.draggedScreenDet.widgets.forEach((e) => {
        let mnemonic_groups = {};
        let mnemonic_type = "UNPREFERRED";
        if (e.name === "trend") {
          const tracks = e.plotConfig.tracks;
          let arr1d = [].concat(...tracks);
          console.log("arr1d ~~~~~~~~~ ", arr1d)
          arr1d.forEach((t) => {
            console.log("------------- ",t)
            for (let i in all_mnemonics) {
              const mnemonicKey = tags.findIndex((tag) => tag == t.name);
              const actualKey = source_mnemonice[mnemonicKey];
              console.log(" source_mnemonice, tags ",mnemonicKey, actualKey)
              // if (all_mnemonics[i].find((o) => o.std_mnemonic === t.name)) {
              //   mnemonic_type = i;
              //   mnemonic_groups[i] = [];
              //   break;
              // }
              if (!actualKey) {
                mnemonic_type = "SOLUTION";
                break;
              }
              if (all_mnemonics[i][actualKey]) {
                mnemonic_type = i;
                mnemonic_groups[i] = [];
                break;
              }
            }

            if (!this.mnemonic_groups[mnemonic_type])
              this.mnemonic_groups[mnemonic_type] = [];
            if (this.mnemonic_groups[mnemonic_type].indexOf(t.name) == -1)
              this.mnemonic_groups[mnemonic_type] = [
                ...this.mnemonic_groups[mnemonic_type],
                t.name,
              ];
          });
        } else {
          if (e.title) {
            for (let i in all_mnemonics) {
              console.log("Dragged widgets:",e);
              // if (all_mnemonics[i].find((o) => o.std_mnemonic === e.title)) {
              //   mnemonic_type = i;
              //   mnemonic_groups[i] = [];
              //   break;
              // }
              const mnemonicKey = tags.findIndex((t) => t == e.title);
              const actualKey = source_mnemonice[mnemonicKey];
              // if (all_mnemonics[i].find((o) => o.std_mnemonic === t.name)) {
              //   mnemonic_type = i;
              //   mnemonic_groups[i] = [];
              //   break;
              // }
              if (!actualKey) {
                mnemonic_type = "SOLUTION";
                break;
              }
              if (all_mnemonics[i][actualKey]) {
                mnemonic_type = i;
                mnemonic_groups[i] = [];
                break;
              }
            }

            if (!this.mnemonic_groups[mnemonic_type])
              this.mnemonic_groups[mnemonic_type] = [];
            if (this.mnemonic_groups[mnemonic_type].indexOf(e.title) == -1)
              this.mnemonic_groups[mnemonic_type] = [
                ...this.mnemonic_groups[mnemonic_type],
                e.title,
              ];
          }
        }
      });
      console.log("step2");
      //for Hydrolics calculator mmenomenics mappings
      if (this.draggedScreenDet && this.draggedScreenDet.isHydraulics) {
        console.log("step3");

        let fields_required = [
          "tot_stk",
          "dmea",
          "stringstrokes",
          "lagstrokes",
          "cyclestrokes",
          "csg1length",
          "csg2length",
          "csg3length",
          "linerlength",
          "openholelength",
          "openholedc1annularstrokes",
          "openholedc2annularstrokes",
          "openholehwdpannularstrokes",
          "openholedp1annularstrokes",
          "openholedp2annularstrokes",
          "linerdc1length",
          "openholedc1length",
          "openholedc2length",
          "openholehwdplength",
          "openholedp1length",
          "openholedp2length",
          "riserstrokes",
          "csg1strokes",
          "csg2strokes",
          "csg3strokes",
          "linerstrokes",
          "totallinerannularstrokes",
          "pumpoutput",
          "boosterpumpoutput",
          "ohdc1annularcapacity",
          "openholedc1annularcapacity",
          "dbtm",
          "ohdc2annularcapacity",
          "openholedc2annularcapacity",
          "ohhwdpannularcapacity",
          "openholehwdpannularcapacity",
          "openholedp1annularcapacity",
          "openholedp2annularcapacity",
          "openholestrokes",
          "totalopenholeannularstrokes",
          "linerdc2length",
          "linerhwdplength",
          "linerdp1length",
          "linerdp2length",
          "linerdp1annularstrokes",
          "linerdp2annularstrokes",
          "linerhwdpannularstrokes",
          "linerdc2annularstrokes",
          "totalcsg1annularstrokes",
          "totalcsg2annularstrokes",
          "totalcsg3annularstrokes",
          "linerdc1annularcapacity",
          "linerdc1annularstrokes",
          "dc1length",
          "dc2length",
          "dp1length",
          "hwdplength",
          "linerhwdpannularcapacity",
          "linerdp1annularcapacity",
          "csg3dc1length",
          "csg3dc2length",
          "csg3hwdplength",
          "csg3dp1length",
          "csg3dp2length",
          "csg3dp2annularstrokes",
          "csg3dp1annularstrokes",
          "csg3hwdpannularstrokes",
          "csg3dc2annularstrokes",
          "csg3dc1annularcapacity",
          "csg3dc1annularstrokes",
          "csg3dc2annularcapacity",
          "hwdpdc1annularstrokes",
          "csg3hwdpannularcapacity",
          "csg3dp1annularcapacity",
          "csg3dp2annularcapacity",
          "csg2dc1length",
          "csg2dc2length",
          "csg2hwdplength",
          "csg2dp1length",
          "csg2dp2length",
          "csg2dp2annularstrokes",
          "csg2dp1annularstrokes",
          "csg2hwdpannularstrokes",
          "csg2dc2annularstrokes",
          "csg2dc1annularcapacity",
          "csg2dc1annularstrokes",
          "csg2dc2annularcapacity",
          "csg2hwdpannularcapacity",
          "csg2dp1annularcapacity",
          "csg2dp2annularcapacity",
          "csg1dc1length",
          "csg1dc2length",
          "csg1hwdplength",
          "csg1dp1length",
          "csg1dp2length",
          "csg1dp2annularstrokes",
          "csg1dp1annularstrokes",
          "csg1hwdpannularstrokes",
          "csg1dc2annularstrokes",
          "csg1dc1annularcapacity",
          "csg1dc1annularstrokes",
          "csg1dc2annularcapacity",
          "csg1hwdpannularcapacity",
          "csg1dp1annularcapacity",
          "csg1dp2annularcapacity",
          "riserlength",
          "totalriserannularstrokes",
          "riserdc1length",
          "riserdc2length",
          "riserhwdplength",
          "riserdp1length",
          "riserdp2length",
          "riserdp2annularstrokes",
          "riserdp1annularstrokes",
          "riserhwdpannularstrokes",
          "riserdc2annularstrokes",
          "riserdc1annularcapacity",
          "riserdc1strokes",
          "riserdc2annularcapacity",
          "risercsg1annularstrokes",
          "riserhwdpannularcapacity",
          "riserdc1annularstrokes",
          "riserdp1annularcapacity",
          "riserdp2annularcapacity",
          "dp2strokes",
          "dp1strokes",
          "hwdpstrokes",
          "dc1strokes",
          "dc2strokes",
          "dp2capacity",
          "dp2stringcapacity",
          "dp1capacity",
          "dp1stringcapacity",
          "dp2length",
          "dp1volume",
          "dp1stringvolume",
          "dp2volume",
          "dp2stringvolume",
          "hwdpcapacity",
          "hwdpstringcapacity",
          "hwdpvolume",
          "hwdpstringvolume",
          "dc2capacity",
          "dc2volume",
          "dc1stringvolume",
          "dc2stringvolume",
          "dc1capacity",
          "dc1stringcapacity",
          "dc2stringcapacity",
          "mud density",
          "drillstringvolume",
        ];
        //get all the menomenics list
        let response = await apiService.DashBoardServices.getSpecialtyList();
        let secialityScreen = response.data;
        if(secialityScreen.length>0){
          const hydrolicsScreen=secialityScreen.find(s=>s.name=="HYDRAULICS");
          if(hydrolicsScreen){
            const bg=hydrolicsScreen.screen.background;
            bg.forEach(b => {
              const bWidgets=b.widgets.reduce((a,c)=>{
                if(c.name!="label"){
                 a.push(c.title)
                }
                return a;
              },[])
              fields_required=[...fields_required,...bWidgets];
            });

          }
        }
        fields_required.forEach((m) => {
          let mnemonic_groups = {};
          let mnemonic_type = "UNPREFERRED";
          for (let i in all_mnemonics) {
            const mnemonicKey = tags.findIndex((tag) => tag == m);
            const actualKey = source_mnemonice[mnemonicKey];
            if (!actualKey) {
                mnemonic_type = "SOLUTION";
                break;
              }
              if (all_mnemonics[i][actualKey]) {
                mnemonic_type = i;
                mnemonic_groups[i] = [];
                break;
              }
            // if (all_mnemonics[i].find((o) => o.std_mnemonic === m)) {
            //   mnemonic_type = i;
            //   mnemonic_groups[i] = [];
            //   break;
            // }
          }

          if (!this.mnemonic_groups[mnemonic_type])
            this.mnemonic_groups[mnemonic_type] = [];
          if (this.mnemonic_groups[mnemonic_type].indexOf(m) == -1)
            this.mnemonic_groups[mnemonic_type] = [
              ...this.mnemonic_groups[mnemonic_type],
              m,
            ];
        });
      }
    },
    async fetchMnemonicDetailsfromDb(wellDet) {
      // const { wellId, wellName, startTime } = wellDet;
      // const startTDate = new Date(startTime).getTime();
      // const endDate = startTDate + 14400000;
      // console.log("start:", startTDate, "end date", endDate);
      // for (let i in this.mnemonic_groups) {
      //   let result = await apiService.WellServices.getTimeLogs({
      //     fields: this.mnemonic_groups[i],
      //     well_id: wellId,
      //     wellbore_name: wellName,
      //     isParse: 1,
      //     type: i,
      //     // days: 0.2,
      //     from: endDate,
      //     to: startTDate,
      //   });
      //   this.completeData = { ...this.completeData, ...result.data.data };
      //   console.log("result", result.data.data);
      // }
      // console.log("total details::", this.completeData);
      // this.saveReplayTostore(this.completeData);
      this.setData(wellDet, this.mnemonic_groups);
    },
    async onTokenLogin(accessToken, refreshToken, customer, scope) {
      // var authData = {
      //   accessToken,
      //   refreshToken,
      //   customer,
      //   scope,
      // };
      // try {
      //   let login = await api.AuthServices.tokenLogin(authData);
      //   if (login.status == 200) {
      //     // this.$router.push("/app");
      //     // window.location.replace("/app")
      //     this.$toast.success("Welcome to Apollo", {
      //       position: "top",
      //       duration: 1000,
      //     });
      //     //
      //   } else {
      //     this.errcode = login.data;
      //     this.$router.push("/auth/login");
      //   }
      // } catch (error) {
      //   this.$router.push("/auth/login");
      // }
    },
    addContextEvent(e) {
      document.querySelector("#menu").style.visbility = "";

      let container_ = e.target.getBoundingClientRect();
      let X = e.clientX;
      let Y = e.clientY - 100;
      setTimeout(() => { }, 250);
      if (this.$store.state.disp.contextId) this.drawMenu(X, Y);

      e.preventDefault();
    },
    foreground() {
      if (
        this.$store.state.disp.contextId &&
        this.$store.state.disp.contextType == "display"
      ) {
        this.$store.dispatch("disp/changeZToTop", {
          displayId: this.$store.state.disp.contextId,
        });
      }
    },
    upForeground() {
      // let menu = document.querySelector("#menu");
      // menu.style.visibility = "";
      if (
        this.$store.state.disp.contextId &&
        this.$store.state.disp.contextType == "display"
      ) {
        this.$store.dispatch("disp/changeZToTop", {
          displayId: this.$store.state.disp.contextId,
        });
        this.$store.dispatch("disp/setContextId", {
          contextId: "",
          contextWidgetId: "",
          contextType: "",
        });
      }
    },
    downBackground() {
      if (
        this.$store.state.disp.contextId &&
        this.$store.state.disp.contextType == "display"
      ) {
        this.$store.dispatch("disp/changeZToBottom", {
          displayId: this.$store.state.disp.contextId,
        });
        this.$store.dispatch("disp/setContextId", {
          contextId: "",
          contextWidgetId: "",
          contextType: "",
        });
      }
    },
    toggleDraggable() {
      if (
        this.$store.state.disp.contextId &&
        this.$store.state.disp.contextType == "display"
      ) {
        this.$store.dispatch("disp/toggleDraggable", {
          displayId: this.$store.state.disp.contextId,
        });
        this.$store.dispatch("disp/setContextId", {
          contextId: "",
          contextWidgetId: "",
          contextType: "",
        });
      } else {
        this.$store.dispatch("rect/toggleDraggable", {
          displayId: this.$store.state.disp.contextId,
          widgetId: this.$store.state.disp.contextWidgetId,
        });
        // this.closePropertyFile();
      }
    },
    toggleResizable() {
      if (
        this.$store.state.disp.contextId &&
        this.$store.state.disp.contextType == "display"
      ) {
        this.$store.dispatch("disp/toggleResizable", {
          id: "",
          displayId: this.$store.state.disp.contextId,
        });
        this.$store.dispatch("disp/setContextId", {
          contextId: "",
          contextWidgetId: "",
          contextType: "",
        });
      } else {
        this.$store.dispatch("rect/toggleResizable", {
          id: "",
          displayId: this.$store.state.disp.contextId,
          widgetId: this.$store.state.disp.contextWidgetId,
        });
        // this.closePropertyFile();
      }
    },
    deleteWidget() {
      console.log(
        "Alert---enter to delete of central",
        this.$store.state.disp.displayIdsAndNames
      );

      if (
        this.$store.state.disp.contextId &&
        this.$store.state.disp.contextType == "display"
      ) {
        this.$store.dispatch("rect/deleteWidget", {
          displayId: this.$store.state.disp.contextId,
          widgetId: this.widgetId,
        });
        this.$store.dispatch("rect/deleteDisplay", {
          displayId: this.$store.state.disp.contextId,
        });
        this.$store.dispatch("disp/deleteDisplay", {
          displayId: this.$store.state.disp.contextId,
        });
        this.$store.dispatch("disp/deleteAlertScreen", {
          displayId: this.$store.state.disp.contextId,
        });
        this.$store.dispatch("disp/setContextId", {
          contextId: "",
          contextWidgetId: "",
          contextType: "",
        });
        this.closeRightNavigation();
      } else {
        this.$store.dispatch("rect/deleteWidget", {
          displayId: this.$store.state.disp.contextId,
          widgetId: this.$store.state.disp.contextWidgetId,
        });
        console.log(
          "******* selected props ****** ",
          this.$store.state.rect.selectedWidgetProps
        );
        if (
          this.$store.state.rect.selectedWidgetProps &&
          this.$store.state.rect.selectedWidgetProps != {} &&
          this.$store.state.rect.selectedWidgetProps.displayId ==
          this.$store.state.disp.contextId &&
          this.$store.state.rect.selectedWidgetProps.widgetId ==
          this.$store.state.disp.contextWidgetId
        ) {
          this.$store.dispatch("rect/toggleRightSideBar", {
            id: -1,
            value: {},
          });
          this.$store.dispatch("disp/togglrRightSideBarWidth", { id: -1 });
        }

        // this.closePropertyFile();
      }
      // TODO: CLOSE PROPERTY FILE
      // this.closePropertyFile();
    },
    hideAllMenus() { },
    hideMenu(e) {
      try {
        let top_menu = document.querySelector("#menu_top");
        if (!top_menu.contains(e.target)) {
          if (top_menu.style.visibility == "visible")
            top_menu.style.visibility = "hidden";
        }
        let menu_top_setting = document.querySelector("#menu_top_setting");
        if (menu_top_setting) {
          if (menu_top_setting.style.visibility == "visible")
            menu_top_setting.style.visibility = "hidden";
        }
        let themes_container_popup = document.querySelector(
          "#themes_container_popup"
        );
        if (themes_container_popup) {
          if (themes_container_popup.style.visibility == "visible")
            themes_container_popup.style.visibility = "hidden";
        }
      } catch (error) {
        console.log(error);
      }

      let menu = document.querySelector("#menu");
      menu.style.visibility = "";

      this.$store.dispatch("disp/setContextId", {
        contextId: "",
        contextWidgetId: "",
        contextType: "",
      });
    },
    drawMenu(mouseX, mouseY) {
      let menu = document.querySelector("#menu");
      menu.style.top = mouseY.toString() + "px";
      menu.style.left = mouseX.toString() + "px";
      menu.style.visibility = "visible";
    },
    windowToggle(name) {
      this.classes = name;
    },
    onlyDisplays(rect) {
      //
      return rect.name == "display";
    },
    startDrag(ev) {
      const rect = ev.target.getBoundingClientRect();
      let offsetX = ev.clientX - rect.x;
      let offsetY = ev.clientY - rect.y;
    },
    addSavedWidgets(widget, gx, gy, displayId, widgetId, backgroundId) {
      widget.displayId = displayId;
      if (widgetId) widget.widgetId = widgetId;
      console.log("window.screen.width : ", window.screen.width);
      if (backgroundId) {
        widget.bgId = backgroundId;
      }

      if (
        widget.name == "label" ||
        widget.name == "numeric" ||
        widget.name == "vertical-bar" ||
        widget.name == "gauge"
      ) {
        widget.title = widget.label[0];
      }

      if (widget.name == "killsheet") {
        widget.height = window.innerHeight - 130;
        widget.width = window.innerWidth;
      }

      if (widget.name == "plotly-graph") {
      }

      if (
        elProps.savedName == "DRILLING INTERVENTION SYSTEM" &&
        widget.name == "trend"
      ) {
        widget.height = window.screen.height - 250;
        widget.width = window.screen.width - 15;
        widget.top = 0;
        widget.left = 5;
        widget.isDis = true;
        // elProps.height = event.target.offsetHeight - 50;
        // elProps.width = event.target.offsetWidth - 15;
        this.$store.dispatch("rect/disScreenWidgetId", {
          widgetId: widget.widgetId,
        });
        // widget.isReplay = false;
      }

      if (elProps.savedName == "GENERAL DRILLING") {
        widget["isReplay"] = elProps.isReplay;
        if (window.screen.width == 1920) {
        } else if (1280 < window.screen.width && window.screen.width <= 1536) {
          widget.left = widget.left * (1 - 0.2);
          if (widget.name == "numeric") {
            widget.width = widget.width * (1 - 0.2);
          }
          if (widget.name == "trend") {
            widget.height = widget.height * (1 - 0.3);
            widget.width = widget.width * (1 - 0.2);
          }
          if (widget.name == "vertical-bar") {
            if (widget.top > 300) widget.top = widget.top * (1 - 0.25);
            console.log(" widget.top ", widget.top);
            widget.height = widget.height * (1 - 0.3);
            widget.width = widget.width * (1 - 0.2);
          }
          if (widget.name == "gauge") {
            if (widget.top > 200 && widget.top < 300)
              widget.top = widget.top * (1 - 0.22);
            if (widget.top > 300) widget.top = widget.top * (1 - 0.25);
            console.log(" widget.top ", widget.top);
            widget.height = widget.height * (1 - 0.26);
            widget.width = widget.width * (1 - 0.26);
          }
        } else if (window.screen.width <= 1280) {
          widget.left = widget.left * (1 - 0.33);
          if (widget.name == "numeric") {
            widget.width = widget.width * (1 - 0.305);
          }
          if (widget.name == "trend") {
            widget.left = widget.left * (1 - 0.08);
            widget.height = widget.height * (1 - 0.47);
            widget.width = widget.width * (1 - 0.33);
          }
          if (widget.name == "vertical-bar") {
            if (widget.top > 300) widget.top = widget.top * (1 - 0.41);
            console.log(" widget.top ", widget.top);
            widget.height = widget.height * (1 - 0.46);
            widget.width = widget.width * (1 - 0.3);
          }
          if (widget.name == "gauge") {
            if (widget.top > 200 && widget.top < 300)
              widget.top = widget.top * (1 - 0.37);
            if (widget.top > 300) widget.top = widget.top * (1 - 0.42);
            console.log(" widget.top ", widget.top);
            widget.height = widget.height * (1 - 0.45);
            widget.width = widget.width * (1 - 0.45);
          }
        }
      }

      if (elProps.savedName == "HYDRAULICS") {
        if (window.screen.width == 1920) {
        } else if (1280 < window.screen.width && window.screen.width <= 1536) {
          if (widget.name == "numeric" || widget.name == "label") {
            widget.width = widget.width * (1 - 0.23);
            widget.height = widget.height * (1 - 0.15);
            widget.top = widget.top * (1 - 0.26);
            // widget.left = widget.left * (1 - 0.2);
            if(widget.name == "label" && widget?.title && 
              (widget?.title?.replace(/\s/g, '')?.toLowerCase() == 'mudparameters' ||
              widget?.title?.replace(/\s/g, '')?.toLowerCase() == 'dimensions')
            ){
              widget.left = widget.left * (1 - 0.126)
            }
            else{
              widget.left = widget.left * (1 - 0.2);
            }
          }
          if (widget.name == "trend") {
            widget.height = widget.height * (1 - 0.27);
            widget.width = widget.width * (1 - 0.20);
            widget.left = widget.left * (1 - 0.15);
          }

          if (widget.name == "Background") {
            widget.left = widget.left * (1 - 0.20);
            widget.width = widget.width * (1 - 0.21);
            widget.height = widget.height * (1 - 0.24);
            widget.top = widget.top * (1 - 0.25);
          }
        } else if (window.screen.width <= 1280) {
          if (widget.name == "numeric" || widget.name == "label") {
            widget.width = widget.width * (1 - 0.35);
            widget.height = widget.height * (1 - 0.35);
            widget.top = widget.top * (1 - 0.43);
            // widget.left = widget.left * (1 - 0.33);
            if(widget.name == "label" && widget?.title && 
              (widget?.title?.replace(/\s/g, '')?.toLowerCase() == 'mudparameters' ||
              widget?.title?.replace(/\s/g, '')?.toLowerCase() == 'dimensions')
            ){
              widget.left = widget.left * (1 - 0.234)
            }
            else{
              widget.left = widget.left * (1 - 0.33);
            }
          }
          if (widget.name == "trend") {
            widget.height = widget.height * (1 - 0.44);
            widget.width = widget.width * (1 - 0.34);
            widget.left = widget.left * (1 - 0.5);
          }

          if (widget.name == "Background") {
            widget.left = widget.left * (1 - 0.335);
            widget.width = widget.width * (1 - 0.34);
            widget.height = widget.height * (1 - 0.425);
            widget.top = widget.top * (1 - 0.43);
          }
        }
        widget["isReplay"] = elProps.isReplay;
      }

      if (elProps.savedName == "DIRECTIONAL") {
        if (window.screen.width == 1920) {
          widget.left = widget.left * (1 + 0.02);
          // widget.height = widget.height * (1 + 0.28);
          // widget.top = widget.top * (1 + 0.28);
          widget.width = widget.width * (1 + 0.02);
        } else if (window.screen.width > 1280 && window.screen.width <= 1536) {
          widget.left = widget.left * (1 - 0.18);
          widget.height = widget.height * (1 - 0.28);
          widget.top = widget.top * (1 - 0.28);
          widget.width = widget.width * (1 - 0.18);
        } else if (window.screen.width > 1098 && window.screen.width <= 1280) {
          widget.left = widget.left * (1 - 0.32);
          if (
            widget.name == "data-table" ||
            widget.name == "gauge" ||
            widget.name == "line-graph" ||
            widget.name == "line-graph-tvd" ||
            widget.name == "directional" ||
            widget.name == "plan-vs-actual"
          ) {
            widget.height = widget.height * (1 - 0.44);
            widget.width = widget.width * (1 - 0.32);
            widget.top = widget.top * (1 - 0.44);
          }
        } else if (window.screen.width <= 1098) {
          widget.left = widget.left * (1 - 0.42);
          if (
            widget.name == "data-table" ||
            widget.name == "gauge" ||
            widget.name == "line-graph" ||
            widget.name == "line-graph-tvd" ||
            widget.name == "directional" ||
            widget.name == "plan-vs-actual"
          ) {
            widget.height = widget.height * (1 - 0.55);
            widget.width = widget.width * (1 - 0.42);
            widget.top = widget.top * (1 - 0.55);
          }
        }
      }

      if (elProps.savedName == "LWD") {
        widget.draggable = false;
        widget.height = window.screen.height - 300;
        widget.width = window.screen.width - 15;
        widget.top = 5;
        widget.left = 5;
      }

      this.$store.dispatch("rect/addNewRect", {
        x: widget.left,
        y: widget.top,
        elProps: widget,
      });
    },
    onDrop(event) {
      if (!event.dataTransfer.getData("elProps")) return;
      elProps = JSON.parse(event.dataTransfer.getData("elProps"));
      console.log("central pannel:", elProps);
      this.draggedScreenDet = elProps;
      // event.target.classList.add("activenav")
      if (elProps.name == "dashboard") {
        // for (let screen of elProps.screens) {
        for (let s = 0; s < elProps.screens.length; s++) {
          let screen = elProps.screens[s];
          let screenId = "id" + Math.random().toString(16).slice(2);
          screen.displayId = screenId;
          screen.savedDbId = elProps.savedDbId;

          setTimeout(this.creatDashboardScreens(screen), 0);

          if (screen.wellDetails) {
            try {
              setTimeout(() => {
                let logId =
                  this.$store.state.data.wells[screen.wellDetails.wellId][
                    "wellbores"
                  ][screen.wellDetails.wellboreId]["logs"][
                    screen.wellDetails.logType
                  ].id;
                let trajectoryLogId = null;
                let trajectoryLog =
                  this.$store.state.data.wells[screen.wellDetails.wellId][
                  "wellbores"
                  ][screen.wellDetails.wellboreId]["logs"]["trajectory"];

                if (trajectoryLog !== undefined || trajectoryLog !== null) {
                  trajectoryLogId = trajectoryLog?.id;
                }

                let rigName =
                  this.$store.state.data.wells[screen.wellDetails.wellId]
                    .rigname;

                let status =
                  this.$store.state.data.wells[screen.wellDetails.wellId][
                    "wellbores"
                  ][screen.wellDetails.wellboreId]["logs"][
                    screen.wellDetails.logType
                  ].state;
                let logTypeId =
                  this.$store.state.data.wells[screen.wellDetails.wellId][
                    "wellbores"
                  ][screen.wellDetails.wellboreId]["logs"][
                    screen.wellDetails.logType
                  ].logTypeId;

                //
                let min = null;
                let max = null;
                if (screen.wellDetails.logType === "depth") {
                  min =
                    this.$store.state.data.wells[screen.wellDetails.wellId][
                      "wellbores"
                    ][screen.wellDetails.wellboreId]["logs"][
                      screen.wellDetails.logType
                    ].startDepth;
                  max =
                    this.$store.state.data.wells[screen.wellDetails.wellId][
                      "wellbores"
                    ][screen.wellDetails.wellboreId]["logs"][
                      screen.wellDetails.logType
                    ].endDepth;
                }

                if (screen.wellDetails.logType === "time") {
                  min =
                    this.$store.state.data.wells[screen.wellDetails.wellId][
                      "wellbores"
                    ][screen.wellDetails.wellboreId]["logs"][
                      screen.wellDetails.logType
                    ].startTime;
                  max =
                    this.$store.state.data.wells[screen.wellDetails.wellId][
                      "wellbores"
                    ][screen.wellDetails.wellboreId]["logs"][
                      screen.wellDetails.logType
                    ].endTime;
                }
                let logData =
                  this.$store.state.data.wells[elProps.wellDetails.wellId][
                  "wellbores"
                  ][elProps.wellDetails.wellboreId]["logs"][
                  elProps.wellDetails.logType
                  ];
                let missingKeys = {
                  topicName: logData.topicname,
                  time_zone: logData.time_zone,
                  well_interval: logData.log_interval || null,
                };
                this.$store.dispatch("disp/setDisplay", {
                  ...screen.wellDetails,
                  logId,
                  trajectoryLogId,
                  status,
                  rigName,
                  display: screenId,
                  logTypeId,
                  min,
                  max,
                  ...missingKeys,
                });
              }, 100);
            } catch (error) {
              console.error("While creating dashboard : ", error);
            }
          }

          setTimeout(() => {
            var id = screen.displayId;
            for (let background of screen.background) {
              let bgId = "id" + Math.random().toString(16).slice(2);
              let bgIdN = "id" + Math.random().toString(16).slice(2);

              background.backgroundId = bgIdN;
              setTimeout(
                this.addSavedWidgets(background, gx, gy, id, bgId),
                1000
              );

              for (let widget of background.widgets) {
                let bgWiId = "bg" + Math.random().toString(16).slice(2);
                setTimeout(
                  this.addSavedWidgets(widget, gx, gy, id, bgWiId, bgIdN),
                  1000
                );
              }
            }
            for (let widget of screen.widgets) {
              let wId = "id" + Math.random().toString(16).slice(2);
              setTimeout(this.addSavedWidgets(widget, gx, gy, id, wId), 1000);
            }
          }, 150);
        }
      } else {
        let id = "id" + Math.random().toString(16).slice(2);
        if (elProps.name == "display") {
          this.$store.state.rect.whichIsActiveInOpentab3.push(
            elProps.savedName
          );
          if (
            elProps.displayId &&
            elProps.displayId.indexOf("generalDrilling") > -1
          ) {
            // Do nothing about displayId
            elProps.height = 375;
            elProps.width = 763;
          } else {
            elProps.displayId = id;
            elProps.height = parseFloat(elProps.height) || this.listHeight;
            elProps.width = window.screen.width - 350;
          }
          console.log(elProps);
          elProps.height =
            elProps.height > window.screen.height - 200
              ? window.screen.height - 200
              : elProps.height;
          elProps.width =
            elProps.width > window.screen.width - 100
              ? window.screen.width - 10
              : elProps.width;
          if (
            elProps.isTaD ||
            elProps.savedName == "DRILLING INTERVENTION SYSTEM" ||
            elProps.savedName == "HYDRAULICS" ||
            elProps.savedName == "WELL CORRELATION" ||
            elProps.savedName == "DIRECTIONAL" ||
            elProps.savedName == "GENERAL DRILLING" ||
            elProps.savedName == "TORQUE AND DRAG" ||
            elProps.savedName == "SWAB AND SURGE" ||
            elProps.savedName == "KILL SHEET" ||
            elProps.savedName == "REPLAY" ||
            elProps.savedName == "LWD" ||
            elProps.isAlertScreen
          ) {
            elProps.left = 5;
            elProps.top = 0;
            elProps.height = window.screen.height - 200;
            elProps.width = window.screen.width;
            elProps.draggable = false;
            elProps.resizable = false;
          }

          const offsetY = elProps.offsetY;
          const offsetX = elProps.offsetX;
          //
          this.$store.dispatch("rect/setDisplayId", {
            displayId: id,
          });

          document.addEventListener(
            "dragend",
            function (e) {
              e = e || window.event;
              (dragX = e.pageX), (dragY = e.pageY);
              let obj = document.getElementById(elId);
              if (obj && obj.offsetParent) {
                do {
                  dragX = dragX - obj.offsetLeft;
                  dragY = dragY - obj.offsetTop;
                } while ((obj = obj.offsetParent));
              }
              dragY = dragY - offsetY;
              dragX = dragX - offsetX;
              test(dragX, dragY);
            },
            false
          );
          setTimeout(this.test3, 0);
          if (elProps.isSaved && !elProps.isspecialityscreen) {
            if (elProps.wellDetails) {
              try {
                if (this.$store.state.data.wells[elProps.wellDetails.wellId]) {
                  let logId =
                    this.$store.state.data.wells[elProps.wellDetails.wellId][
                      "wellbores"
                    ][elProps.wellDetails.wellboreId]["logs"][
                      elProps.wellDetails.logType
                    ].id;
                  let trajectoryLogId = null;
                  let trajectoryLog =
                    this.$store.state.data.wells[elProps.wellDetails.wellId][
                    "wellbores"
                    ][elProps.wellDetails.wellboreId]["logs"]["trajectory"];

                  if (trajectoryLog !== undefined || trajectoryLog !== null) {
                    trajectoryLogId = trajectoryLog?.id;
                  }
                  let rigName =
                    this.$store.state.data.wells[elProps.wellDetails.wellId]
                      .rigname;
                  let status =
                    this.$store.state.data.wells[elProps.wellDetails.wellId][
                      "wellbores"
                    ][elProps.wellDetails.wellboreId]["logs"][
                      elProps.wellDetails.logType
                    ].state;

                  let logTypeId =
                    this.$store.state.data.wells[elProps.wellDetails.wellId][
                      "wellbores"
                    ][elProps.wellDetails.wellboreId]["logs"][
                      elProps.wellDetails.logType
                    ].logTypeId;

                  let min = null;
                  let max = null;
                  if (elProps.wellDetails.logType === "depth") {
                    min =
                      this.$store.state.data.wells[elProps.wellDetails.wellId][
                        "wellbores"
                      ][elProps.wellDetails.wellboreId]["logs"][
                        elProps.wellDetails.logType
                      ].startDepth;
                    max =
                      this.$store.state.data.wells[elProps.wellDetails.wellId][
                        "wellbores"
                      ][elProps.wellDetails.wellboreId]["logs"][
                        elProps.wellDetails.logType
                      ].endDepth;
                  }

                  if (elProps.wellDetails.logType === "time") {
                    min =
                      this.$store.state.data.wells[elProps.wellDetails.wellId][
                        "wellbores"
                      ][elProps.wellDetails.wellboreId]["logs"][
                        elProps.wellDetails.logType
                      ].startTime;
                    max =
                      this.$store.state.data.wells[elProps.wellDetails.wellId][
                        "wellbores"
                      ][elProps.wellDetails.wellboreId]["logs"][
                        elProps.wellDetails.logType
                      ].endTime;
                  }
                  let logData =
                    this.$store.state.data.wells[elProps.wellDetails.wellId][
                    "wellbores"
                    ][elProps.wellDetails.wellboreId]["logs"][
                    elProps.wellDetails.logType
                    ];
                  let missingKeys = {
                    topicName: logData.topicname,
                    time_zone: logData.time_zone,
                    well_interval: logData.log_interval || null,
                  };
                  setTimeout(() => {
                    this.$store.dispatch("disp/setDisplay", {
                      ...elProps.wellDetails,
                      logId,
                      trajectoryLogId,
                      status,
                      rigName,
                      display: id,
                      logTypeId,
                      min,
                      max,
                      ...missingKeys,
                    });
                  }, 100);
                }
              } catch (error) {
                console.error("while creating screen ", error);
              }
            }
            setTimeout(() => {
              if (elProps.background)
                for (let background of elProps.background) {
                  let bgId = "id" + Math.random().toString(16).slice(2);
                  let bgIdN = "id" + Math.random().toString(16).slice(2);

                  background.backgroundId = bgIdN;
                  setTimeout(
                    this.addSavedWidgets(background, gx, gy, id, bgId),
                    605
                  );

                  for (let widget of background.widgets) {
                    let bgWiId = "bg" + Math.random().toString(16).slice(2);
                    setTimeout(
                      this.addSavedWidgets(widget, gx, gy, id, bgWiId, bgIdN),
                      605
                    );
                  }
                }
              if (elProps.widgets)
                for (let widget of elProps.widgets) {
                  let wId = "id" + Math.random().toString(16).slice(2);
                  setTimeout(
                    this.addSavedWidgets(widget, gx, gy, id, wId),
                    605
                  );
                }
            }, 200);
          }
        }
      }

      this.isAlertScreen = elProps.isAlertScreen;
      // console.log("Alert---knowing displayIds", this.$store.state.rect);
    },
    creatDashboardScreens(display) {
      console.log(
        "🚀 ➽ file: CentralPanel.vue:1151  ➽ creatDashboardScreens  ➽ display ⏩",
        display
      );
      let height = window.screen.height - 300;
      let width = window.screen.width;
      if (height < display.height && width < display.width) {
        display.height = height;
        display.width = width;
      }
      this.$store.dispatch("disp/addNewDisplay", {
        x: display.left,
        y: display.top,
        elProps: display,
      });
    },
    test3(isSaved, data) {
      this.$store.dispatch("disp/addNewDisplay", {
        x: gx,
        y: gy,
        elProps: { ...elProps, locker: true },
      });
      if (elProps.displayId.indexOf("generalDrilling") > -1) {
        this.$store.dispatch("disp/setDisplay", {
          display: elProps.displayId,
          logId: "",
          logType: "",
          wellId: "",
          wellboreId: "",
          wellboreState: "",
        });
        setTimeout(() => {
          this.addWidgetsForGeneralDrilling(elProps.displayId);
        }, 0);
      }
      if (elProps.isTaD) {
        this.$store.dispatch("rect/addNewRect", {
          x: 0,
          y: 0,
          elProps: {
            displayId: elProps.displayId,
            draggable: true,
            height: elProps.height - 70,
            name: "broom-stick-plot",
            offsetX: 14.79296875,
            offsetY: 6.23046875,
            draggable: false,
            widgetId: "id" + Math.random().toString(16).slice(2),
            width: elProps.width,
            isReplay: elProps.isReplay,
          },
        });
      }
      if (elProps.isCorrelation) {
        this.$store.dispatch("rect/addNewRect", {
          x: 0,
          y: 0,
          elProps: {
            displayId: elProps.displayId,
            draggable: false,
            height: elProps.height - 40,
            name: "correlation-plot",
            isCorrelationMap: true,
            offsetX: 14.79296875,
            offsetY: 6.23046875,
            widgetId: "id" + Math.random().toString(16).slice(2),
            width: elProps.width,
            locker: true,
            isReplay:elProps.isReplay
          },
        });
      }
      if (elProps.isAlertScreen) {
        this.$store.dispatch("rect/addNewRect", {
          x: 0,
          y: 0,
          elProps: {
            displayId: elProps.displayId,
            draggable: false,
            height: elProps.height - 70,
            name: "alert-screen",
            offsetX: 14.79296875,
            offsetY: 6.23046875,
            widgetId: "id" + Math.random().toString(16).slice(2),
            width: elProps.width,
          },
        });
      }
      if (isSaved) {
        setTimeout(() => {
          for (let background of data.background) {
            background.displayId = data.displayId;
            background.widgetId = "id" + Math.random().toString(16).slice(2);
            this.$store.dispatch("rect/addNewRect", {
              x: gx,
              y: gy,
              elProps: {
                name: "Background",
                left: background.left,
                top: background.top,
                width: background.width,
                height: background.height,
                zIndex: background.zIndex,
                color: background.color,
              },
            });
            for (let widget of background.widgets) {
              widget.bgId = background.widgetId;
              widget.displayId = data.displayId;
              widget.widgetId = "id" + Math.random().toString(16).slice(2);
              this.$store.dispatch("rect/addNewRect", {
                x: gx,
                y: gy,
                elProps: widget,
              });
            }
          }
          for (let widget of data.widgets) {
            widget.displayId = data.displayId;
            widget.widgetId = "id" + Math.random().toString(16).slice(2);
            this.$store.dispatch("rect/addNewRect", {
              x: gx,
              y: gy,
              elProps: widget,
            });
          }
        }, 0);
      }
    },
    addWidgetsForGeneralDrilling(genDrillDisplayId) {
      let generalDrillingWidgets = generalDrillingDefault;
      for (let widget of generalDrillingWidgets) {
        widget.displayId = genDrillDisplayId;
        widget.widgetId = "id" + Math.random().toString(16).slice(2);
        this.$store.dispatch("rect/addNewRect", {
          x: gx,
          y: gy,
          elProps: widget,
        });
      }
    },
    //dropzone
    activateEv(index, displayId) {
      //
      this.activatedIndex = index;
      this.$store.dispatch("disp/setActive", { id: index });
      this.$store.dispatch("disp/setSelectedDisplay", displayId);
    },
    deactivateEv(index) {
      //
      this.$store.dispatch("disp/unsetActive", { id: index });
    },
    changePosition(newRect, index) {
      this.$store.dispatch("disp/setTop", { id: index, top: newRect.top });
      this.$store.dispatch("disp/setLeft", { id: index, left: newRect.left });
      this.$store.dispatch("disp/setWidth", {
        id: index,
        width: newRect.width,
      });
      this.$store.dispatch("disp/setHeight", {
        id: index,
        height: newRect.height,
      });
    },
    changeSize(newRect, index) {
      this.changePosition(newRect, index);
    },
    changeProperties(newRect, index) {
      this.changePosition(newRect, index);
    },
    // openProperty(index, config) {
    //   if (this.isRightSideBar) {
    //     this.closeRightNavigation();
    //     this.isRightOpen = false;
    //   } else {
    //     this.isRightSideBar = true;
    //     this.propertyIndex = index;
    //     this.selectedWidgetConfig = config;
    //     (this.toggle_right_Sidebar = "0"), (this.toggle_right_margin = "17");
    //   }
    // },

    openProperty(index, config) {
      console.log("config ", { config }, config.widgetId);
      // console.log("this.$store.state.rect.selectedWidgetProps this.$store.state.rect.selectedWidgetProps.widgetId", this.$store.state.rect.selectedWidgetProps)
      if (
        this.$store.state.rect.selectedWidgetProps &&
        this.$store.state.rect.selectedWidgetProps.widgetId
      ) {
        if (
          config.widgetId == this.$store.state.rect.selectedWidgetProps.widgetId
        ) {
          this.$store.dispatch("disp/togglrRightSideBarWidth", { id: -1 });
        } else {
        }
      } else {
        this.$store.dispatch("disp/togglrRightSideBarWidth", { id: -1 });
      }
      this.$store.dispatch("rect/toggleRightSideBar", {
        id: index,
        value: config,
      });
    },

    closeRightNavigation() {
      this.$store.dispatch("rect/toggleRightSideBar", {
        id: -1,
        value: {},
      });
      this.$store.dispatch("disp/togglrRightSideBarWidth", { id: -1 });
    },
    setNavbarOpen: function () {
      this.navbarOpen = !this.navbarOpen;
      this.isRightOpen = !this.isRightOpen;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    dark() {
      document.querySelector("body").classList.add("dark-mode");
      document.querySelector("nav").classList.add("dark-mode");
      document.querySelector("div").classList.add("dark-mode");
      document.querySelector("button").classList.add("dark-mode");
      document.querySelector("a").classList.add("text-red-600");
      this.darkMode = true;
      this.$emit("dark");
    },
    light() {
      document.querySelector("body").classList.remove("dark-mode");
      this.darkMode = false;
      this.$emit("light");
    },

    //     modeToggle() {
    //         if(this.darkMode || document.querySelector('body').classList.contains('dark-mode')) {
    //             this.light()
    //         } else {
    //             this.dark()
    //         }
    //     },

    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    // CHECKING SCREEN ACTIVITY CODE STARTS
    resetInactivityTimer() {
      clearTimeout(this.inactivityTimer);
      if (!this.inactive) {
        this.inactivityTimer = setTimeout(
          this.showPopup,
          this.inactivityThreshold
        );
      }
    },
    showPopup() {
      this.inactive = true;
      // alert("1 hour of inactivity detected. Showing popup.");
    },
    stay_active() {
      this.inactive = false;
      clearTimeout(this.logout_timer);
      this.resetInactivityTimer();
    },
    async logout() {
      clearTimeout(this.inactivityTimer);
      clearTimeout(this.logout_timer);
      clearInterval(this.sec_counter_interval);

      // LOGOUT FUNCTIONALITY HAVE TO IMPLEMENT HERE
      let logout_body = this.$serviceHelpers.getDetails("y:a");
      await apiService.AuthServices.emp_logout(logout_body);
      localStorage.clear();
      sessionStorage.clear();
      // this.$router.push("/auth/login");
      window.location.reload();
    },
    // CHECKING SCREEN ACTIVITY CODE ENDS
  },
};
</script>

<style scopped src="../assets/styles/themeMode.css" rel="stylesheet">
body {
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
}

.drag-el {
  background-color: #3498db;
  color: white;
  padding: 5px;
  margin-bottom: 10px;
  width: 100px;
  height: 100px;
}

#app {
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  /* font-family: "Lato", sans-serif; */
  /* "Lato", sans-serif; */
}

.filler {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
}

.displayNone {
  display: none;
}

/* .box-shaddow {
  box-shadow: 10px 10px 15px 0px rgba(125, 125, 125, 1);
} */
.resize {
  resize: both;
  background-color: blue;
  overflow: hidden;
}

/* width */
::-webkit-scrollbar {
  height: 2px;
  width: 2px;
}

/* Track */
/* ::-webkit-scrollbar-track {
  background: #19181b;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: gray;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: gray;
} */
/* ::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
} */
.top-16 {
  top: 3.9rem;
}

.height {
  height: 10px !important;
}

.container_ {
  height: 100%;
  width: 100%;
  background: #1b1a1f;

  /* margin: 0 0 20px;
  position: relative;

  background: azure;

  box-shadow: 0px 6px 20px #001;
  display: flex;
  justify-content: center;
  align-items: center; */
}

/* // Context Menu */
.menu {
  height: 155px;
  width: 115px;
  visibility: hidden;
  position: absolute;
  z-index: 9999;
  border: 1px solid #04070e;
  box-shadow: 2px 2px 6px rgb(0 0 0 / 30%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu>p {
  font-size: 10px;
}

.item {
  flex: 1;
  width: 100%;
  /* background: #04070e; */
  font-size: 10px;
  letter-spacing: 1px;
  cursor: context-menu;
  /* color: #5aba81; */
  /* border-bottom: 1px solid #4b55631f; */
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  align-items: center;
  transition: background 0.2s, color 0.1s;

  /* &:hover {
		cursor: pointer;
		background: slateblue;
		color: white;
		transition: background .2s, color .1s;
	} */
}

.item:hover {
  cursor: pointer;
  background: #0e1016;

  color: white;
  transition: background 0.2s, color 0.1s;
  transition: var(--transition);
}

/* .dark-mode{
  background: red !important;
  color: white !important;
  transition-delay: background 0.2s, color 0.1s;
} */
.right_list_prop {
  height: 100vh;
}

.activenav {
  color: var(--activeTextColor);
  border-bottom: 2px solid;
  cursor: pointer;
}
</style>
