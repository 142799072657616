import Axios from "axios";
import constant from "../constant";
import api from "../dictionary/index";

export default {
    gettingAlerts: async (data) => {
        try {
            let response = await Axios.get(constant.DATASERVICES_URL + api.alerts.GETTING_ALERTS(), { params: data })
            return response
        } catch (error) {
            return error
        }
    },
    gettingCustomMblNumbers: async (payload) => {
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.alerts.GETTING_MBL_NUMBERS(), payload)
            return response
        } catch (error) {
            return error
        }
    },
    
    selectedWellAlertInfo: async (data) => {
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.alerts.COMMUNICATION_CONFIG_SEARCH(), data)
            return response
        } catch (error) {
            return error
        }
    },
    saveTheWellAlertInfo: async (data) => {
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.alerts.COMMUNICATION_CONFIG_SAVE_UPDATE(), data)
            return response
        } catch (error) {
            return error

        }
    },
    gettingCommunicationConfigList: async () => {
        try {
            let response = await Axios.get(constant.DATASERVICES_URL + api.alerts.COMMUNICATION_CONFIG_LIST())
            return response

        } catch (error) {
            return error

        }
    },
    gettingMiscellaneousList: async () => {
        try {
            let response = await Axios.get(constant.DATASERVICES_URL + api.alerts.MISCELLANEOUS_LIST())
            return response
        } catch (error) {
            return error
        }
    },
    listAnnotation: async () => {
        try {
            let alertAnno = await Axios.get(
                constant.DATASERVICES_URL + api.alerts.ANNOTATIONS_LIST()
            );
            return alertAnno;
        } catch (error) {
            console.error(error);
            return error;
        }
    },
    listNodeAnnotation: async () => {
        try {
            let alertAnno = await Axios.get(
                constant.BASE_URL + api.alerts.ANNOTATIONS()
            );
            return alertAnno;
        } catch (error) {
            console.error(error);
            return error;
        }
    },
    listDefaultAlerts: async () => {
        try {
            let alertsList = await Axios.get(
                constant.BASE_URL + api.alerts.ALERTS_LIST()
            );
            return alertsList;
        } catch (error) {
            console.error(error);
            return error;
        }
    },
    getAlertHistory: async (data) =>{
        try {
            let alertsHistData = await Axios.get(  constant.CH_BASEURL + api.alerts.ALERT_HIST(), { params: data });
            return alertsHistData;
        } catch (error) {
            console.error(error);
            return error;
        }
    },
    saveAlertMapping: async (data) =>{
        try {
            let alertMapping = await Axios.post( constant.CH_BASEURL + api.alerts.SAVEORGET_ALERT_PREF(), data);
            return alertMapping;
        } catch (error) {
            console.error(error);
            return error;
        }
    },
    getAlertsMapped: async (data) =>{
        try {
            let alertMapped = await Axios.get( constant.CH_BASEURL + api.alerts.SAVEORGET_ALERT_PREF(),  { params: data }  );
            return alertMapped;
        } catch (error) {
            console.error(error);
            return error;
        }
    },
    getPrevAlerts: async (data) =>{
        try {
            let prevAlerts = await Axios.get( constant.CH_BASEURL + api.alerts.PREV_ALERTS(),  { params: data }  );
            return prevAlerts;
        } catch (error) {
            console.error(error);
            return error;
        }
    },

}