import Axios from "axios";
import constant from "../constant";
import api from "../dictionary/index";

export default {
    saveDashboard: async (data) => {
        try {

            let response = await Axios.post(constant.BASE_URL + api.dashboard.SAVE_DASHBOARD(), data);
            return response;
        } catch (error) {

            return error;
        }
    },
    getSavedContents: async (data) => {
        try {
            let response = await Axios.get(constant.BASE_URL + api.dashboard.GET_SAVED_CONTENT());
            
            return response;
        } catch (error) {
            
            return error;
        }
    },
    getNameExists: async (data) => {
        try {
            let response = await Axios.get(constant.BASE_URL + api.dashboard.NAME_EXISTS(), {
                params: data
            });
            return response;
        } catch (error) {

            return error;
        }
    },
    deleteSavedContent: async (data) => {
        try {
            let response = await Axios.delete(constant.BASE_URL + api.dashboard.DELETE_SAVED_CONTENT(), { data: data });
            return response;
        } catch (error) {

            return error;
        }
    },
    deleteSavedBha: async (data) => {
        try {
            let response = await Axios.delete(constant.BASE_URL + api.dashboard.DELETE_SAVED_BHA(), { data: data });
            return response;
        } catch (error) {

            return error;
        }
    },
    renameSavedContent: async (data) => {
        try {
            let response = await Axios.put(constant.BASE_URL + api.dashboard.RENAME_SAVED_CONTENT(), data);
            return response;
        } catch (error) {

            return error;
        }
    },
    getSavedContentList: async (data) => {
        try {
            let response = await Axios.get(constant.BASE_URL + api.dashboard.LIST_SAVED_CONTENT(), {
                params: data
            });
            return response;
        } catch (error) {

            return error;
        }
    },
    getSpecialtyList: async () => {
        try {
            let response = await Axios.get(constant.BASE_URL + api.dashboard.LIST_SPECIALTIY_SCREEN());
            return response;
        } catch (error) {

            return error;
        }
    },
}