module.exports = {
  "DateAndTime": "Unitless",
  "DMEA": "FT",
  "DBTM": "FT",
  "BPOS": "FT",
  "ROPA": "FT/HR",
  "ROP5A": "FT/HR",
  "HKLX": "K#",
  "WOBX": "K#",
  "TQX": "FTLB",
  "RPMA": "Unitless",
  "SPPA": "PSI",
  "CHKP": "PSI",
  "DIFFPRES": "PSI",
  "SPM1": "SPM",
  "SPM2": "SPM",
  "TVA": "BBL",
  "TVCA": "BBL",
  "MFOP": "%",
  "SMFOP": "%",
  "STKC": "Unitless",
  "LSTK": "Unitless",
  "DRTM": "FT",
  "STKC1": "Unitless",
  "STKC2": "Unitless",
  "TOTSPM": "Unitless",
  "STIS": "Unitless",
  "TVT": "BBL",
  "TVCT": "BBL",
  "TV01": "BBL",
  "TV02": "BBL",
  "TV03": "BBL",
  "TV04": "BBL",
  "TV05": "BBL",
  "TV06": "BBL",
  "TV07": "BBL",
  "TTV1": "BBL",
  "TGPM": "GPM",
  "STKCU": "Unitless",
  "TVCAU": "BBL",
  "TVCTU": "BBL",
  "MSE": "PSI",
  "SP220_2": "BLS",
  "SP220_3": "UNITS",
  "BOTTOM": "Unitless",
  "DIR": "Unitless",
  "TTVC1": "BBL",
  "BPM1": "BPM",
  "BPM2": "BPM",
  "TBPM": "BPM",
  "STTM": "SEC",
  "TOB": "HR:MIN",
  "FOB": "FT",
  "TTVC": "BBL",
  "ROP1A": "FT/HR",
  "TTVC1U": "BBL",
  "TTVCU": "BBL",
  "STKCA": "Unitless",
  "TVCAA": "BBL",
  "TVCTA": "BBL",
  "TTVC1A": "BBL",
  "TTVCA": "BBL",
  "MG1": "API",
  "MG1C": "API",
  "MFTP": "DEGF",
  "SP159_1": "LEVEL",
  "SP159_2": "LEVEL",
  "GPM1": "GPM",
  "GPM2": "GPM",
  "OP": "K#",
  "OPREF": "K#",
  "MSE2": "PSI",
  "AVOL": "BBL",
  "TV01C": "BBL",
  "TV01CU": "BBL",
  "TV01CA": "BBL",
  "TV02C": "BBL",
  "TV02CU": "BBL",
  "TV02CA": "BBL",
  "TV03C": "BBL",
  "TV03CU": "BBL",
  "TV03CA": "BBL",
  "TV04C": "BBL",
  "TV04CU": "BBL",
  "TV04CA": "BBL",
  "TV05C": "BBL",
  "TV05CU": "BBL",
  "TV05CA": "BBL",
  "TV06C": "BBL",
  "TV06CU": "BBL",
  "TV06CA": "BBL",
  "TV07C": "BBL",
  "TV07CU": "BBL",
  "TV07CA": "BBL",
  "DSVM_157": "FT",
  "SINC_157": "DEG",
  "SAZC_157": "DEG",
  "SMTF_157": "DEG",
  "SGTF_157": "DEG",
  "SP157_2": "DEG",
  "SP157_3": "DEG"
}
